// src/components/Privacy.js
import { Container, Text, Title } from '@mantine/core';
import styles from './Privacy.module.css';
import Footer from './Footer';
import Navbar from './Navbar';

const Privacy = () => {
  return (
    <>
    <Navbar />
    <Container className={styles.privacyContainer}>
      <Title order={1} className={styles.title}>Privacy Policy</Title>
      <Text className={styles.company}>AIVideo.to</Text>

      <Title order={2} className={styles.sectionTitle}>1. Introduction</Title>
      <Text className={styles.sectionText}>
        AiVideo.to ("we," "our," or "us") operates AIVideo.to (the "Service"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our Service.
      </Text>

      <Title order={2} className={styles.sectionTitle}>2. Information We Collect</Title>
      <Text className={styles.sectionText}>
        <strong>Personal Information:</strong> We may collect personal information such as your name, email address, and payment information when you create an account or use our Service.
      </Text>
      <Text className={styles.sectionText}>
        <strong>Usage Data:</strong> We collect information on how you access and use the Service, including your IP address, browser type, and pages visited.
      </Text>

      <Title order={2} className={styles.sectionTitle}>3. How We Use Your Information</Title>
      <Text className={styles.sectionText}>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information to improve our Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent, and address technical issues</li>
        </ul>
      </Text>

      <Title order={2} className={styles.sectionTitle}>4. Disclosure of Your Information</Title>
      <Text className={styles.sectionText}>
        We do not sell your personal information. We may disclose your information to:
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of AiVideo.to</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of users of the Service or the public</li>
        </ul>
      </Text>

      <Title order={2} className={styles.sectionTitle}>5. Security of Your Information</Title>
      <Text className={styles.sectionText}>
        We take reasonable measures to protect your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure.
      </Text>

      <Title order={2} className={styles.sectionTitle}>6. Changes to This Privacy Policy</Title>
      <Text className={styles.sectionText}>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </Text>

      <Title order={2} className={styles.sectionTitle}>7. Contact Us</Title>
      <Text className={styles.sectionText}>
        If you have any questions about this Privacy Policy, please contact us at: support@aivideo.to
      </Text>
    </Container>
    <Footer />
    </>
  );
};

export default Privacy;
