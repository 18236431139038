import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Center, Container, Loader, Skeleton } from '@mantine/core';

const SubscribedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const checkSubscription = async () => {
      if (currentUser) {
        const email = currentUser.email;
        try {
          const sheetyResponse = await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[email]=${email}`);
          const sheetyData = await sheetyResponse.json();
          const userData = sheetyData.users[0];

          if (userData?.freeTrial !== 'active' && userData?.subscriptionStatus !== 'active') {
            navigate('/subscribe');
          } else {
            setUserData(userData);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching user data from Sheety:', error);
          navigate('/subscribe');
        }
      } else {
        navigate('/login');
      }
    };

    checkSubscription();
  }, [currentUser, navigate]);

  if (loading) {
    return (
    <div>
        <Container mt={200}>
          <Center>
            <Loader color="indigo" size="xl" />
          </Center>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
    </div>
    );
  }

  return React.cloneElement(children, { userData });
};

export default SubscribedRoute;
