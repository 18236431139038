import { useAuth } from "../contexts/AuthContext";
import { useState } from 'react';
import OpenAI from 'openai';
import styles from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Input, Loader, Progress, Skeleton, Container, Center, Modal, Text, Anchor} from "@mantine/core";
import Sidebar from "./Sidebar";
import { IconCircleCheck, IconEye, IconSparkles, IconVideoPlus } from "@tabler/icons-react";
import UpgradeCard from "./UpgradeCard";
import { useDisclosure } from "@mantine/hooks";
// import AWS from 'aws-sdk';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Note: In a production app, you should use a backend to make API calls
});

const s3 = new AWS.S3({
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  region: import.meta.env.VITE_AWS_REGION,
});

const getContentType = (filename) => {
  const ext = filename.split('.').pop().toLowerCase();
  switch (ext) {
    case 'mp3':
      return 'audio/mpeg';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'webp':
      return 'image/webp';
    default:
      return 'application/octet-stream';
  }
};

const base64ToBlob = (base64, type = 'application/octet-stream') => {
  const binary = atob(base64.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([buffer], { type: type });
};

const generateFolderName = (title, uid) => {
  const sanitizedTitle = title.replace(/[^a-zA-Z0-9]/g, '-');
  const datetime = new Date().toISOString().replace(/[:.]/g, '-');
  return `${sanitizedTitle}-${datetime}-${uid}`;
};


const PROGRESS = {
  0: 100,
  1: 50,
  2: 34,
  3: 25,
  4: 20,
  5: 16,
  6: 0
}

const PROMPT_STYLE = [
  "high contrast, epic cinematic, soft studio lights, rim light, absurd, amazing, funny, itricate, hyper detailed, ultra realistic, soft colors, 8K",
  "aesthetic, painterly style, modern ink, expressive pose, urbanpunk, abstract texture multilayer background",
  "abstract style. non-representational, colors and shapes, expression of feelings, imaginative, highly detailed,",
  "gloomy background, brutal, majestic, cinematic, dramatic, digital painting, expressionism, emotionally profound, close up, unforgettable, high quality",
  "Surreal, ethereal, dreamy, mysterious, fantasy, highly detailed. psychedelic, vibrant. digital artwork, illustrative, painterly, matte painting. key visual. octane render, , volumetric, dramatic lighting, glossy, lense flare, shiny surface, black and fire orange color scheme. bokeh, epic, grainy",
  "eye-catching, beautiful details, overaction, fever, complex background, chaos composition, epic. 32k, intricate background, hyperrealistic, 32k oil painting, oil splash, liquid splash",
  "digital painting meets analog film, noir style, hyper-realistic, dark black, night, heavy fog, wet, textured, ambient light, rim light, highly detailed",
  "rainy, digital painting , dramatic, gloomy, despair, looking to bottom, dark tones, high quality, unforgettable, very intricate, masterpiece",
  "cyberpunk, black, hyperdetailed, hyperrealism extremely detailed epic masterwork, dark fantasy detailed matte 8k resolution concept art intricately detailed Splash art trending on Artstation dynamic lighting hyperdetailed",
  "8k, rough, dark background, muted colors, epic composition, dramatic, digital painting, emotionally profound, very intricate",
  "Long Exposure Photography, high quality, highly detailed, dazzling display, translucent, beautiful silhouette light particles, backlit, back lights, HDR"
]

// Array of objects containing the template data
  const topicTemplates = [
    {
      id: 1,
      template: 'Who is/was/were/are X?',
      example: 'Who was Amelia Earhart?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/088lwignbp/out.mp4',
    },
    {
      id: 2,
      template: 'What is/was X?',
      example: 'What is the Doppler Effect?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/64zrxppeoj/out.mp4',
    },
    {
      id: 3,
      template: "X's greatest Y",
      example: "Glorfindel's greatest feats",
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/g8y8cl4asn/out.mp4',
    },
    {
      id: 4,
      template: 'How is/was/were X [action]?',
      example: 'How were the Pyramids made?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/ehpqhv3dex/out.mp4',
    },
    {
      id: 5,
      template: 'Can X [action] Y?',
      example: 'Can Itachi beat Jiraya?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/lkt97loc2i/out.mp4',
    },
    {
      id: 6,
      template: 'What if X?',
      example: 'What if everything is a dream?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/s4697uzmj7/out.mp4',
    },
    {
      id: 7,
      template: 'Why X is/was Y?',
      example: 'Why was slavery abolished?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/bvxpr80znx/out.mp4',
    },
    {
      id: 8,
      template: 'Lessons from X',
      example: 'Lessons from Plato',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/1as78y75mu/out.mp4',
    },
    {
      id: 9,
      template: 'The history of X',
      example: 'The history of Peaky Blinders',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/hdodxxcp68/out.mp4',
    },
    {
      id: 10,
      template: 'The legacy of X',
      example: 'The legacy of Cleopatra',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/ll9autrrv1/out.mp4',
    },
    {
      id: 11,
      template: 'Does X like Y?',
      example: 'Does Deadpool like Spiderman?',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/tenk3jeylm/out.mp4',
    },
    {
      id: 12,
      template: 'X the Y',
      example: 'Hippolyta the Queen of the Amazons',
      video: 'https://d27ug7nm5nfkza.cloudfront.net/renders/v6u6x2pu6h/out.mp4',
    },
  ];

const Dashboard = ({userData}) => {
  // console.log(userData.remaining);

  const { currentUser } = useAuth();
  // const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  
  const [videoTitle, setVideoTitle] = useState('');
  const [generatedContent, setGeneratedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [videoDuration, setVideoDuration] = useState(300);
  const [remaining, setRemaining] = useState(userData?.remaining);
  const [quota, setQuota] = useState(userData?.quota);
  const [rowId, setRowId] = useState(userData?.id)
  const [freeTrial, setFreeTrial] = useState(userData?.freeTrial)
  const [step, setStep] = useState(0)

  const [settingup, setSettingup] =  useState(false)
  const [videoUrl, setVideoUrl] =  useState("")


 const uploadToS3 = async (file, folder, filename) => {
  const params = {
    Bucket: import.meta.env.VITE_S3_BUCKET_NAME,
    Key: `${folder}/${filename}`,
    Body: file,
    ContentType: getContentType(filename),
    ContentLength: file.size,
  };

  const data = await s3.upload(params).promise();
  return data.Location; // URL of the uploaded file
};


   const generateScript = async (title) => {
    const response = await openai.chat.completions.create({
        model: "gpt-4o",
        response_format: { "type": "json_object" },
        messages: [
            { 
                role: "system", 
                content: `
                    You are a helpful assistant that generates short video scripts. Provide the script text as JSON with the following schema:
                    {
                        "parts": [
                            {
                                "part": "string"
                            }
                        ]
                    }
                    Each part should be a single sentence of exactly 10 words. Do not include any meta text, scene descriptions, greetings, or instructions.
                `
            },
            { 
                role: "user", 
                content: `Generate a short script for a video titled "${title}". The script should be exactly 100 words long, divided into 10 parts. Each part should be a single sentence of exactly 10 words. Title is already present. Do not generate title. Start the script directly without title.` 
            }
        ],
    });
    // console.log(response.choices[0].message.content);
  
    const fullScript = JSON.parse(response.choices[0].message.content);
    // console.log(fullScript);
    
    return fullScript.parts.map(item => item.part);
};

  const generateVoiceover = async (script) => {
  const response = await openai.audio.speech.create({
    model: "tts-1",
    voice: "alloy",
    input: script,
  });

  const arrayBuffer = await response.arrayBuffer();
  const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
  return blob;
};

// const generateImages = async (prompts) => {
//     console.log('Generating images with prompts:', prompts);
//   const images = [];
//   const promises = [];

//   for (let i = 0; i < prompts.length; i++) {
//     const prompt = prompts[i];
//     const formData = new FormData();
//     formData.append('prompt', prompt);
//     formData.append('model', "sd3-large");
//     // formData.append('height', 1792);
//     // formData.append('width', 1024);
//     formData.append('output_format', "jpeg");
//     formData.append('aspect_ratio', "9:16");
//     formData.append('negative_prompt', "typography, watermark, bad hands, bad anatomy, extra digits, low quality, worst quality, logo, signature, trademark, name");

//     const requestOptions = {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${import.meta.env.VITE_STABILITY_API_KEY}`, // Replace with your API key
//         'Accept': 'application/json' // Correct accept header
//       },
//       body: formData
//     };

//     promises.push(
//       fetch('https://api.stability.ai/v2beta/stable-image/generate/sd3', requestOptions)
//         .then(response => response.json())
//         .then(data => {
//           // console.log(data);
//           if (data?.image) {
//             const base64 = data.image;
//             const imageBlob = base64ToBlob(base64, 'image/jpeg');
//             const imageFile = new File([imageBlob], `image${i + 1}.jpg`, { type: 'image/jpeg' });
//             images.push({ index: i, file: imageFile });
//           }
//         })
//     );
//   }

//   await Promise.all(promises);

//   images.sort((a, b) => a.index - b.index);
  
//   // console.log(images);

//   return images.map(image => image.file);
// };

const generatePrompts = async (title, scriptLines) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
        {
          role: "system",
          content: `
            You are a creative assistant specialized in generating prompts for image generation models. 
            For the title and each script line, generate a descriptive prompt that conveys the essence 
            and specifies details that would result in a visually appealing image. Your prompts should 
            be specific and provide clear imagery that aligns with the given text. The prompt should ensure no text appears on the image.
            Return the prompts as a single string, with each prompt separated by the delimiter "|". 
            Do not include any additional descriptors or labels; just provide the prompt text.
          `
        },
        {
          role: "user",
          content: `
            Generate image prompts based on the following title and script lines. 
            The title of the video is "${title}". The script lines are:
            ${scriptLines.join('\n')}
            There will always be 1 title and 10 script lines. So generate 11 prompts for 1 title and 10 script lines in that order. Provide the prompts in the specified format.
          `
        }
      ],
    });
    
    // console.log(response.choices[0].message.content);
    
    // Split the response based on the delimiter
    const promptsString = response.choices[0].message.content;
    const promptsArray = promptsString.split('|').map(prompt => prompt.trim());

    return promptsArray; // Return the array directly
  } catch (e) {
    console.error("Error generating prompts:", e.message);
    
    // Consider adding a delay before retrying to avoid rapid recursion
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    return generatePrompts(title, scriptLines); // Retry on error
  }
};


const generateImages = async (prompts) => {
    // console.log('Generating images with prompts:', prompts);

    // Array to hold all the promises
    const imagePromises = [];

    for (let index = 0; index < prompts.length; index++) {
      const prompt = prompts[index];

      // Push a new promise to the array
      imagePromises.push((async () => {
        // Make a POST request to generate an image
        const response = await fetch('https://spiff.guru/generate-image?prompt=' + prompt);

        // if (!response.ok) {
        //   throw new Error(`Failed to generate image for prompt ${index}`);
        // }

        // console.log(response);
        

        const data = await response?.json();
        const imageUrl = data?.image_url;

        // Fetch the generated image as a blob
        const imageResponse = await fetch(imageUrl);
        if (!imageResponse.ok) {
          throw new Error(`Failed to fetch image from URL ${imageUrl}`);
        }

        const imageBlob = await imageResponse.blob();
        return { index, blob: imageBlob };
      })());
    }

    // Wait for all image generation promises to complete
    const images = await Promise.all(imagePromises);

    // Sort images by index to ensure correct order
    images.sort((a, b) => a.index - b.index);

    // Convert blobs to files with proper naming
    const imageFiles = images.map((image, i) => ({
      filename: `image_${i + 1}.webp`,
      file: new File([image.blob], `image_${i + 1}.webp`, { type: 'image/webp' }),
    }));

    return imageFiles.map(image => image.file);
};


  const updateUsage = async () => {
    // Update the row in the Google Sheets API
    await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users/${rowId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: {          
          remaining: remaining-1,          
        }
      })
    });

    setRemaining(remaining-1)
  }

const handleSubmit = async (e) => {
  e.preventDefault();

  if (remaining <= 0) {
    alert('Quota Exhausted. Please upgrade for more or wait for quota reset.');
    return;
  }

  if(videoTitle.length > 50) {
    alert('Video title too long. Max 50 characters.');
    return;
  }

  setIsLoading(true);
  setError(null);

  try {
    const scriptParts = await generateScript(videoTitle);
    setStep(1)

    const title = videoTitle; // Assuming the first element is the title
    const mainScript = scriptParts;
    const restOfScript = mainScript.join(' ');

    // Generate a unique folder name
    const folderName = generateFolderName(videoTitle, currentUser.uid);

    // Generate voiceover for the title separately
    const titleVoiceoverBlob = await generateVoiceover(videoTitle);
    const titleVoiceoverFile = new File([titleVoiceoverBlob], 'titleVoiceover.mp3', { type: 'audio/mpeg' });
    const titleVoiceoverUrl = await uploadToS3(titleVoiceoverFile, folderName, 'titleVoiceover.mp3');

    // Generate voiceover for the rest of the script
    const voiceoverBlob = await generateVoiceover(restOfScript);
    const voiceoverFile = new File([voiceoverBlob], 'voiceover.mp3', { type: 'audio/mpeg' });
    const voiceoverUrl = await uploadToS3(voiceoverFile, folderName, 'voiceover.mp3');

    setStep(2)


    // Ensure the number of images matches the number of script parts (including the title)
    const prompts = await generatePrompts(videoTitle, scriptParts);
    // console.log(prompts);
    
    const images = await generateImages(prompts);
    const imageUrls = await Promise.all(images.map((image, index) => uploadToS3(image, folderName, `image${index + 1}.webp`)));

    setStep(3)

    const titleAudio = new Audio(URL.createObjectURL(titleVoiceoverFile));
    await new Promise(resolve => titleAudio.addEventListener('loadedmetadata', resolve));
    const titleDurationSeconds = titleAudio.duration;

    const audio = new Audio(URL.createObjectURL(voiceoverFile));
    await new Promise(resolve => audio.addEventListener('loadedmetadata', resolve));
    const audioDurationSeconds = audio.duration;

    const params = {
      uid: currentUser.uid,
      title,
      script: mainScript,
      titleVoiceover: titleVoiceoverUrl,
      voiceover: voiceoverUrl,
      images: imageUrls,
      titleDuration: Math.ceil(titleDurationSeconds * 30), // 30 fps
      videoDuration: Math.ceil(audioDurationSeconds * 30), // 30 fps
    };

    // console.log(params);

// Call the render API
const renderResponse = await fetch('https://spiff.guru/render', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    ...params,
    type: 'info',  // Specify this is an info video
    format: 'PORTRAIT' // Default format for info videos
  }),
});

const renderData = await renderResponse.json();

if (renderResponse?.ok) {
  setVideoUrl(renderData?.outputFile)
  setStep(4)


      const sheetParams = {
        uid: currentUser.uid,
        title,
        script: restOfScript,
        titleVoiceover: titleVoiceoverUrl,
        voiceover: voiceoverUrl,
        images: imageUrls.join(','),
        titleDuration: Math.ceil(titleDurationSeconds * 30), // 30 fps
        videoDuration: Math.ceil(audioDurationSeconds * 30), // 30 fps
      };
      // Save details to Google Sheets via Sheety API
      const url = 'https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/reels';
      const body = {
        reel: { ...sheetParams, videoUrl: renderData?.outputFile }
      };
      const data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const row = await data?.json()
      // console.log(row);

      setGeneratedContent({
        id: row.reel.id,
        title,
        scriptParts: scriptParts, // Exclude the title
        titleVoiceover: titleVoiceoverUrl,
        voiceover: voiceoverUrl,
        images: imageUrls,
        titleDuration: Math.ceil(titleDurationSeconds * 30), // 30 fps
        videoDuration: Math.ceil(audioDurationSeconds * 30), // 30 fps
      });

      setVideoDuration(Math.ceil(titleDurationSeconds * 30) + Math.ceil(audioDurationSeconds * 30)); // 30 fps

      setStep(5)

      await updateUsage();

      setStep(6)
    } else {
      throw new Error('Failed to render video');
    }
  } catch (err) {
    console.error('Error generating content:', err);
    setError('An error occurred while generating the video content. Please try again.');
    setGeneratedContent(null)
    setStep(0)
    setVideoDuration(null)
    setVideoUrl('')
  } finally {
    setIsLoading(false);
  }
};

  // useEffect(() => {
  //   const checkSubscriptionStatus = async () => {
  //     if (!currentUser) {
  //       navigate('/login');
  //       return;
  //     }

  //     setSettingup(true)

  //     const email = currentUser.email;
  //     try {
  //       const sheetyResponse = await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[email]=${email}`);
  //       const sheetyData = await sheetyResponse.json();
  //       const userData = sheetyData.users[0];

  //       if (!userData || userData.subscriptionStatus !== 'active') {
  //         navigate('/subscribe');
  //       }

  //       setQuota(userData.quota)
  //       setRemaining(userData.remaining)
  //       setRowId(userData.id)
  //     } catch (error) {
  //       console.error('Error fetching subscription status:', error);
  //       window.location.reload
  //       // navigate('/pricing');
  //     } finally {
  //       setSettingup(false)
  //     }
  //   };

  //   checkSubscriptionStatus();
  // }, [currentUser, navigate]);

  if (settingup) {
    return (
      <div className={styles["dashboard-container"]}>
        <div className={styles["form-container"]} style={{textAlign: 'center'}}>
          <Loader color="indigo" size="xl" />
        </div>
        <Container>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </div>
    )
  }

  return (
    <div className={styles.section}>
      <Sidebar />
      <div className={styles["dashboard-container"]}>

        <div className={styles["dashboard-header"]}>
          <h2><IconVideoPlus size="1.4rem" stroke={2.5} /> New Video</h2>
          <div>
            <Badge
              size="md"
              variant="gradient"
              gradient={{ from: 'pink', to: 'teal', deg: 208 }}
            >
              {freeTrial ? "Free Trial" : "Usage Remaining"}: {remaining}/{quota}
            </Badge>
          </div>
        </div>
        
        {!generatedContent && (
          <div className={styles["form-container"]}>
            {/* <h1>Video Generator App</h1> */}
            <Card>

            <form onSubmit={handleSubmit}>
              <Input className={styles.input}
                value={videoTitle}
                onChange={(e) => setVideoTitle(e.target.value)}
                placeholder="Enter video title"
                required max={50} />     
              <Button className={styles.button} type="submit" loading={isLoading} loaderProps={{ type: 'dots' }} leftSection={<IconSparkles />} size="md">
                Generate Video
              </Button>
            </form>

            <Center>
              <Anchor
                onClick={open}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1c7ed6' }}
              >
                See Topic Templates for Best Results
              </Anchor>
            </Center>
            
            <Modal
              opened={opened}
              onClose={close}
              title="Optimize Your Topics for Best Results"
              size="lg"
              centered
              overlayBlur={3}
              overlayOpacity={0.55}
              transition="fade"
              transitionDuration={300}
            >
              <Text size="sm">
                This is a short guide on how to optimize your topics to create videos with aivideo.to.
              </Text>
              <Text size="sm" mt="md">
                Not all topics will produce great results. However, below are some topic templates/patterns
                that produce the best results:
              </Text>

              <div style={{ marginTop: '20px' }}>
                <ul>
                  {topicTemplates.map((template) => (
                    <li key={template.id} style={{ marginBottom: '20px' }}>
                      <strong>{template.template}</strong> e.g. {template.example}
                      <br />
                      <video controls width="auto" style={{ marginTop: '10px', height: "300px" }}>
                        <source src={template.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </li>
                  ))}
                </ul>
              </div>

              <Text size="sm" mt="xl">
                Now it's your turn! Try these patterns, unleash your creativity, and see what amazing videos you can generate.
              </Text>

              <Text size="sm" mt="md" weight={500}>
                Happy creating!
              </Text>
            </Modal>

            {error && <p className={styles.error}>{error}</p>}
            </Card>
          </div>
        )}

        {
          isLoading ?
          (
          <>
            <Progress.Root size="xl">
              {
                step >= 1 &&
                <Progress.Section value={16} color="pink">
                  <Progress.Label>Script Generated</Progress.Label>
                </Progress.Section>
              }
              {
                step >= 2 &&
                <Progress.Section value={16} color="cyan">
                  <Progress.Label>Voiceover Rendered</Progress.Label>
                </Progress.Section>
              }
              {
                step >= 3 &&
                <Progress.Section value={16} color="orange">
                  <Progress.Label>Images Generated</Progress.Label>
                </Progress.Section>
              }
              {
                step >= 4 &&
                <Progress.Section value={16} color="violet">
                  <Progress.Label>Video Rendered</Progress.Label>
                </Progress.Section>
              }
              {
                step >= 5 &&
                <Progress.Section value={16} color="yellow">
                  <Progress.Label>Video Saved</Progress.Label>
                </Progress.Section>
              }
              {
                step >= 6 &&
                <Progress.Section value={16} color="green">
                  <Progress.Label>Finishing...</Progress.Label>
                </Progress.Section>
              }
              <Progress.Section value={PROGRESS[step]} color="gray">
                <Progress.Label>Processing...</Progress.Label>
              </Progress.Section>
              {/* <Progress.Section value={15} color="orange">
                <Progress.Label>Other</Progress.Label>
              </Progress.Section> */}
            </Progress.Root>
            
            {/* <Center mt={10}>  
              <Loader color="indigo" size="xl" />
            </Center> */}
            <Card shadow="sm" padding="lg" radius="md" withBorder className={styles.card}>
              <Center>
                <Loader color="indigo" size="xl" />

                {/* <IconCircleCheck size="5rem" stroke={2.5} color="green" /> */}
              </Center>
              <h2>Please Wait. Video is Processing..</h2>
              <p>Do not close this window.</p>              
            </Card>

            {/* <Container mt={10}>
              <Skeleton height={50} circle mb="xl" />
              <Skeleton height={8} radius="xl" />
              <Skeleton height={8} mt={6} radius="xl" />
              <Skeleton height={8} mt={6} width="70%" radius="xl" />
            </Container> */}
          </>

          )
          : <></>
        }
        
        {generatedContent && (
          <div className={styles["generated-content"]}>
            <Card shadow="sm" padding="lg" radius="md" withBorder className={styles.card}>
              <Center>
                <IconCircleCheck size="5rem" stroke={2.5} color="green" />
              </Center>
              <h2>Video Generated Successfully</h2>
              <Button variant="outline" className={styles.button} component={Link} to={`/ai-video/${generatedContent.id}`} leftSection={<IconEye />}>View Video</Button>
            </Card>
          </div>
        )}

        <UpgradeCard remaining={remaining} accountEmail={currentUser?.email} quota={quota} />

      </div>
    </div>
  );
};

export default Dashboard;
