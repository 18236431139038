import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from "react-router-dom";
import { Notifications, notifications } from '@mantine/notifications';
import pricingPlans from '../plans';
import { 
  Badge, 
  Button, 
  Center, 
  Container, 
  List, 
  Loader,
  Alert,
  ThemeIcon,
  Text,
  Group,
  Avatar,
  Paper,
  SimpleGrid,
  Divider,
  MantineProvider
} from '@mantine/core';
import { 
  IconCheck, 
  IconLogout, 
  IconAlertCircle, 
  IconShieldCheck,
  IconMail,
  IconCreditCard,
  IconStarFilled
} from '@tabler/icons-react';

const features = [
  "Music Videos",
  "Info Videos",
  "No Watermarks",
  // "Unlimited Exports (videos, audio, images, text)",
  "Dynamic AI Subtitles"
];

const tags = ["NEW 🎉", "POPULAR 🔥", "RECOMMENDED ✅", "SUPERUSER 😎"];
const colors = ["grape", "pink", "violet", "red"];

const testimonials = [
  {
    name: "Sarah Chen",
    role: "Teacher",
    avatar: "https://i.imgur.com/bU9jAeJ.png",
    text: "I made info shorts with AIVideo about school topics to keep my students engaged. All of them aced the last test.",
    rating: 5
  },
  {
    name: "Mike Roberts",
    role: "Content Creator",
    avatar: "https://i.imgur.com/NS6cd3h.png",
    text: "I create AI music videos daily for my followers and they love it. Such a cool feature!",
    rating: 5
  },
  {
    name: "Ana Silva",
    role: "YouTube Creator",
    avatar: "https://i.imgur.com/j4LsOFM.png",
    text: "I run 5 faceless shorts-only YT channels with AI Video on autopilot. 3 got monetised. Best investment ever!",
    rating: 5
  }
];

const countries = [
  "United States", "United Kingdom", "Canada", "Australia", "Germany", 
  "France", "Spain", "Italy", "Netherlands", "Singapore", "Japan"
];

const PricingPage = () => {
  const { currentUser, signOutUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [status, setStatus] = useState({
    isLoading: false,
    isChecking: true,
    error: null,
    customerId: location?.state?.customerId
  });

  const handleCheckout = async (price) => {
    setStatus(prev => ({ ...prev, isLoading: true, error: null }));
    
    try {
      if (!currentUser) {
        throw new Error('Please log in to continue');
      }

      const email = currentUser.email;
      let cusId = status.customerId;

      if (!cusId) {
        const sheetyResponse = await fetch(
          `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[email]=${email}`
        );
        
        if (!sheetyResponse.ok) {
          throw new Error('Unable to retrieve user information. Please try again.');
        }
        
        const sheetyData = await sheetyResponse.json();
        const userData = sheetyData.users[0];

        if (!userData) {
          throw new Error('User account not found. Please contact support.');
        }

        cusId = userData?.customerId;
        setStatus(prev => ({ ...prev, customerId: cusId }));
      }

      const successurl = `https://aivideo.to/success?customerId=${cusId}&email=${email}&userId=${price}`;
      const cancelurl = `https://aivideo.to/dashboard`;

      const checkoutResponse = await fetch(
        `https://spiff.guru/create-checkout-session/${cusId}/${price}/${email}?` +
        `successurl=${encodeURIComponent(successurl)}&cancelurl=${encodeURIComponent(cancelurl)}&price=${encodeURIComponent(price)}`
      );

      if (!checkoutResponse.ok) {
        throw new Error('Unable to create checkout session. Please try again.');
      }

      const session = await checkoutResponse.json();
      
      if (session?.sessionUrl) {
        window.location.href = session.sessionUrl;
      } else {
        throw new Error('Invalid checkout session. Please try again.');
      }
    } catch (err) {
      setStatus(prev => ({ 
        ...prev, 
        error: err.message || 'An unexpected error occurred. Please try again.' 
      }));
    } finally {
      setStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

const showSubscriptionNotification = () => {
    const country = countries[Math.floor(Math.random() * countries.length)];
    const plans = ["Starter", "Basic", "Standard", "Premium"];
    const plan = plans[Math.floor(Math.random() * plans.length)];
    const minutes = Math.floor(Math.random() * 30) + 1;
    
    notifications.show({
      title: 'New Subscription! 🎉',
      message: `${minutes} min ago: Someone from ${country} subscribed to ${plan}`,
      color: 'teal',
      radius: 'md',
      withBorder: true,
      autoClose: 4000,
      style: { 
        backgroundColor: 'white',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '300px'
      },
      styles: (theme) => ({
        root: {
          padding: '12px 16px',
          marginBottom: '10px'
        },
        title: {
          fontSize: '14px',
          fontWeight: 600,
          marginBottom: '4px'
        },
        description: {
          fontSize: '13px',
          color: "#000"
        }
      })
    });
  };

  // Show first notification after 3 seconds
  useEffect(() => {
    const initialDelay = setTimeout(() => {
      showSubscriptionNotification();
    }, 3000);

    return () => clearTimeout(initialDelay);
  }, []);

  // Show notifications every 30-45 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() > 0.3) {
        showSubscriptionNotification();
      }
    }, Math.random() * (45000 - 30000) + 30000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        const sheetyResponse = await fetch(
          `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[email]=${currentUser.email}`
        );
        
        if (!sheetyResponse.ok) {
          throw new Error('Unable to check subscription status');
        }
        
        const sheetyData = await sheetyResponse.json();
        const userData = sheetyData.users[0];

        if (userData?.subscriptionStatus === 'active' || userData?.freeTrial === 'active') {
          // Optionally handle active subscription case
          navigate('/dashboard');
        }
      } catch (error) {
        setStatus(prev => ({ 
          ...prev, 
          error: 'Unable to verify subscription status. Please refresh the page.' 
        }));
      } finally {
        setStatus(prev => ({ ...prev, isChecking: false }));
      }
    };

    checkSubscriptionStatus();
  }, [currentUser, navigate]);

  if (status.isChecking) {
    return (
      <Container size="sm" style={{ marginTop: '10vh', textAlign: 'center' }}>
        <Loader color="indigo" size="xl" />
        <Text mt="md">Checking subscription status...</Text>
      </Container>
    );
  }

  return (
    <MantineProvider>
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Notifications 
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 9999,
          width: '300px'
        }}
      />

    <Container size="xl" py="xl">
      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
        <h1>Choose Your Plan</h1>
        <Text color="dimmed" size="lg" mb="xl">
          Create professional AI videos with our powerful tools
        </Text>
      </div>

      {status.error && (
        <Alert 
          icon={<IconAlertCircle size={16} />}
          title="Error"
          color="red"
          variant="filled"
          mb="xl"
        >
          {status.error}
        </Alert>
      )}

      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', 
        gap: '2rem',
        marginBottom: '2rem' 
      }}>
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            style={{
              border: '1px solid #e9ecef',
              borderRadius: '8px',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            {/* <Center mb="md">
              {tags[index] && (
                <Badge size="lg" variant="outline" color={colors[index]}>
                  {tags[index]}
                </Badge>
              )}
            </Center> */}

            <Text size="xl" weight={700} align="center" mb="xs">
              {plan.title}
            </Text>
            
            <Group align="center" justify="center" gap="xs">
              <Text size="sm" style={{ textDecoration: 'line-through' }} color="dimmed">
                {plan.old}
              </Text>
              <Text size="xl" weight={700} color="blue">
                {plan.price}
              </Text>
            </Group>

            <Text align="center" color="dimmed" mb="md">
              {plan.reels} /mo
            </Text>

            <List
              spacing="sm"
              center
              icon={
                <ThemeIcon color="teal" variant="light" size={24} radius="xl">
                  <IconCheck size={16} />
                </ThemeIcon>
              }
              mb="xl"
            >
              {features.map((feature, idx) => (
                <List.Item key={idx}>{feature}</List.Item>
              ))}
            </List>

            <Button
              fullWidth
              loading={status.isLoading}
              onClick={() => handleCheckout(plan.priceId)}
              style={{ marginTop: 'auto' }}
            >
              Get Started
            </Button>
          </div>
        ))}
      </div>

 {/* Testimonials Section */}
      <Container size="xl" py="xl">
        <Text align="center" size="xl" weight={700} mb="xl">
          Trusted by Content Creators Worldwide
        </Text>
        <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }} spacing="lg">
          {testimonials.map((testimonial, index) => (
            <Paper
              key={index}
              withBorder
              p="lg"
              radius="md"
              style={{ height: '100%' }}
            >
              <Group mb="md">
                <Avatar src={testimonial.avatar} radius="xl" />
                <div>
                  <Text size="sm" weight={500}>{testimonial.name}</Text>
                  <Text size="xs" color="dimmed">{testimonial.role}</Text>
                </div>
              </Group>
              <Text size="sm" mb="sm">
                "{testimonial.text}"
              </Text>
              <Group spacing={4}>
                {[...Array(testimonial.rating)].map((_, i) => (
                  <IconStarFilled key={i} size={16} color="#FFD700" />
                ))}
              </Group>
            </Paper>
          ))}
        </SimpleGrid>
      </Container>

      <Divider my="xl" />

      {/* Trust Badges Section */}
      <Container size="sm" py="xl">
        <SimpleGrid cols={{ base: 1, sm: 3 }} spacing="xl">
          <div style={{ textAlign: 'center' }}>
            <ThemeIcon size={48} radius="xl" color="blue" variant="light">
              <IconShieldCheck size={24} />
            </ThemeIcon>
            <Text size="sm" weight={500} mt="md">30-Day Money Back</Text>
            <Text size="xs" color="dimmed">No questions asked refund</Text>
          </div>
          
          <div style={{ textAlign: 'center' }}>
            <ThemeIcon size={48} radius="xl" color="green" variant="light">
              <IconCreditCard size={24} />
            </ThemeIcon>
            <Text size="sm" weight={500} mt="md">Secure Payments</Text>
            <Text size="xs" color="dimmed">Protected by Stripe</Text>
          </div>

          <div style={{ textAlign: 'center' }}>
            <ThemeIcon size={48} radius="xl" color="violet" variant="light">
              <IconMail size={24} />
            </ThemeIcon>
            <Text size="sm" weight={500} mt="md">24/7 Email Support</Text>
            <Text size="xs" color="dimmed">Average response in 2 hours</Text>
          </div>
        </SimpleGrid>

        <Group justify="center" mt="xl">
          <Text size="sm">Logged in as: {currentUser?.email}</Text>
          <Button 
            variant="subtle" 
            color="gray" 
            leftSection={<IconLogout size={16} />}
            onClick={signOutUser}
          >
            Sign out
          </Button>
        </Group>
      </Container>
    </Container>
    </div>
  </MantineProvider>
  );
};

export default PricingPage;