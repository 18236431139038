import styles from "./App.module.css";
import { Container } from '@mantine/core';
import { Helmet } from "react-helmet";
import { PricingCards } from "./components/Pricing";

import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';


function AIMusicLanding() {
  return (
    <>
    <Helmet>
    <title>Best AI Music Video Generator</title>
    <meta name="description" content="Make music videos with AI. Enter a song title, select genre, and click generate. Your Music Video will be ready in minutes." />

    <meta property="og:title" content="Best AI Music Video Generator" />
    <meta property="og:url" content="https://aivideo.to/ai-music-video-generator" />
    <meta property="og:image" content="blob:https://imgur.com/3c655b6b-9b1a-46e6-9254-7277b4c92870" />
    <meta property="og:description"
      content="Make music videos with AI. Enter a song title, select genre, and click generate. Your Music Video will be ready in minutes." />
    <meta property="og:site_name" content="AI Video" />
    
    <meta name="twitter:title" content="Best AI Music Video Generator" />
    <meta name="twitter:description"
      content="Make music videos with AI. Enter a song title, select genre, and click generate. Your Music Video will be ready in minutes." />
    <meta name="twitter:image" content="blob:https://imgur.com/3c655b6b-9b1a-46e6-9254-7277b4c92870" />
   
    <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://aivideo.to/ai-music-video-generator",
          "name": "AI Video",
          "description": "Make music videos with AI. Enter a song title, select genre, and click generate. Your Music Video will be ready in minutes.",
          "image": "blob:https://imgur.com/3c655b6b-9b1a-46e6-9254-7277b4c92870",
          "publisher": {
            "@type": "Organization",
            "name": "AI Video",
            "logo": {
              "@type": "ImageObject",
              "url": "https://aivideo.to/logo.png"
            }
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://aivideo.to/"
          }
        }
        )}
      </script>
    </Helmet>
      <Container fluid className={styles.container}>
        <Navbar />
          <HeroSection title="Best AI Music Video Generator" description="Make music videos with AI. Enter a song title, select genre, and click generate. Your Music Video will be ready in minutes." showDemo={true} ctaText="Generate AI Music Videos" ctaIcon="music" isMusicVideo={true} secondaryCTA={{text: "Create Shorts with AI", link: "/", show: true, icon: "info"}} />
          {/* <VideoCarousel /> */}
          {/* <KeyFeatures features={features} /> */}
          {/* <UseCases useCases={useCases} /> */}
          {/* <WhyChooseAI /> */}

          <PricingCards />
          <Footer />
      </Container>
    </>
  );
}

export default AIMusicLanding;
