import React, { Suspense } from 'react';
import { Container, Title, Text, Button, SimpleGrid, Avatar, Rating, Group, Image } from '@mantine/core';
import styles from './HeroSection.module.css';
import { Link } from 'react-router-dom';
// import Showcase from './Showcase';
import { IconMusic, IconSparkles } from '@tabler/icons-react';
import LoadingScreen from './LoadingScreen'

// Lazy load components
const Showcase = React.lazy(() => import('./Showcase'));


const ctaIcons = {
  "info": <IconSparkles />,
  "music": <IconMusic />,
}
const HeroSection = ({title, description, bg="#f3f9fb", color="#474f85", showDemo=true, ctaText="Create Shorts with AI", ctaIcon="info", isMusicVideo=false, secondaryCTA={show: false, text: "", link: "", icon: "music"}}) => {
  // console.log(bg);
  
  return (
    <div className={styles.hero} style={{background: bg, color: color}}>
      <Container className={styles.container}>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 2 }}
      spacing={{ base: 10, sm: 'xl' }}
      verticalSpacing={{ base: 'md', sm: 'xl' }}>
          <div>
            <Title className={styles.title}>{title}</Title>
            <Text className={styles.description}>
              {description}
            </Text>
            <Button className={styles.button} component={Link} to="/dashboard" leftSection={ctaIcons[ctaIcon]}>{ctaText}</Button>

            {secondaryCTA?.show ?
              <Button className={styles.secondaryCTA} component={Link} to={secondaryCTA?.link} leftSection={ctaIcons[secondaryCTA?.icon]}>{secondaryCTA?.text}</Button>
            : ""}
            {/* <Text size='sm'>Try for <b>7 Days</b> FREE</Text> */}

            <Group style={{marginTop: 40}}>
              <Avatar.Group>
                <Avatar src="https://pbs.twimg.com/profile_images/1514863683574599681/9k7PqDTA_400x400.jpg" />
                <Avatar src="https://t3.ftcdn.net/jpg/03/62/50/16/360_F_362501638_FimxwTbbGUoRtjaypXDPGAyYCFvfvYy0.jpg" />  
                <Avatar src="https://www.adi.org.in/media/com_easysocial/photos/238/269/d06c40534ccfa641700d805eec1f4b56_thumbnail.jpg" />
                <Avatar src="https://media.istockphoto.com/id/1150572112/photo/close-up-of-a-smiling-mid-adult-businesswoman.jpg?s=612x612&w=0&k=20&c=6HG75dzb_AMZUhfRZCXWQUc1FG557ZEpKzh6BCxntr8=" />
                <Avatar src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg" />
                <Avatar src="https://images.squarespace-cdn.com/content/v1/54ee6b54e4b094722873774d/1661193481466-5KGJY88ZD0IWGF2HTD6X/304.jpg" />
                {/* <Avatar>+500</Avatar> */}
              </Avatar.Group>

              <div>
                <Rating value={5} readOnly />
                <Text size='sm'><b>201</b> creators love AI Video </Text>
              </div>
            </Group>
          </div>
          <div>
            {showDemo ? 
              <Suspense fallback={<LoadingScreen />}><Showcase  isMusicVideo={isMusicVideo} /></Suspense>
            : 
            // <Image src="https://aivideo.to/hero.png" alt="Demo Image" className={styles.image} />
            <iframe className={styles.youtubeiframe} src="https://www.youtube.com/embed/QDbj3sAsrYc?si=4at-HD_ijFBseBpS" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            }
          </div>          
        </SimpleGrid>
        {/* <Grid>
          <Grid.Col span={12} md={6} className={styles.content}>
            <Title className={styles.title}>AI Video: Revolutionize Your Content Creation</Title>
            <Text className={styles.description}>
              Welcome to AI Video, the ultimate app for effortlessly creating stunning video reels. Whether you're a social media influencer, a marketer, or someone who loves sharing memorable moments, AI Video is designed to help you craft captivating videos in no time.
            </Text>
            <Button className={styles.button}>Download AI Video Today!</Button>
          </Grid.Col>
          <Grid.Col span={12} md={6} className={styles.imageWrapper}>
            <Image src="https://via.placeholder.com/500" alt="Demo Image" className={styles.image} />
          </Grid.Col>
        </Grid> */}
      </Container>
    </div>
  );
};

export default HeroSection;
