import Footer from "./Footer"
import HeroSection from "./HeroSection"
import KeyFeatures from "./KeyFeatures"
import Navbar from "./Navbar"
import { PricingCards } from "./Pricing"
import VideoCarousel from "./VideoCarousel"
import WhyChooseAI from "./WhyChooseAI"
import { Container } from "@mantine/core"
import styles from "../App.module.css";
import UseCases from "./UseCases"
import { IconBadgeCc, IconBrandTabler, IconBrandYoutube, IconDatabase, IconFileArrowRight, IconPresentationAnalytics, IconRocket, IconSparkles } from "@tabler/icons-react"

const TiktokLanding = () => {
  return (
    <>
    <Container fluid className={styles.container}>

      <Navbar />
      <HeroSection title="AI TikTok Video Generator: Generate Viral Videos Instantly" description="Simplify TikTok content creation with our AI-powered TikTok generator. Produce high-quality, AI-generated TikTok videos in minutes and grow your audience effortlessly. Experience the future of TikTok video creation!" ctaText="Create TikToks with AI" showDemo={false} />

      <VideoCarousel title="AI Generated TikTok Videos" description="Create faceless tiktok videos in 1 minute" />

      <KeyFeatures features={features} />
      <UseCases useCases={useCases} />

      <WhyChooseAI />

      <PricingCards />


      <HeroSection title="TikTok AI Video Generator: Automate Your Content Creation" description="Create faceless TikTok videos that go viral with our AI TikTok generator. Harness the power of AI to produce professional, engaging content without showing your face. Start generating AI TikTok videos now!" bg="#fff" ctaText="Create TikToks with AI" showDemo={false} />

      <Footer />
    </Container>
    </>
  )
}

export default TiktokLanding

const features = [
  {
    title: "AI TikTok Generator",
    description: "Automatically create faceless TikTok videos with AI-crafted scripts, images, and voiceovers.",
    icon: <IconSparkles size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Versatile Content",
    description: "Generate faceless TikTok content on any topic, from science to fiction.",
    icon: <IconPresentationAnalytics size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "High-Quality TikToks",
    description: "Produce TikToks with professional-grade visuals and audio.",
    icon: <IconBrandYoutube size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Dynamic AI Subtitles",
    description: "Enhance your faceless TikToks with AI subtitles that increase viewer engagement and accessibility.",
    icon: <IconBadgeCc size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Fast TikTok Generation",
    description: "Create and download your TikTok AI video generator outputs in just one minute.",
    icon: <IconRocket size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Unlimited Exports",
    description: "Enjoy unlimited exports of your TikTok videos, audio, images, and text without any watermarks.",
    icon: <IconFileArrowRight size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Lifetime Storage",
    description: "Keep all your TikToks safe and accessible forever with our lifetime storage feature.",
    icon: <IconDatabase size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "User-Friendly Interface",
    description: "Easily create faceless TikTok videos with an intuitive platform designed for users of all skill levels.",
    icon: <IconBrandTabler size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
];

const useCases = [
  {
    title: "Viral Faceless TikTok Videos with AI",
    description: "Discover how to use a TikTok AI video generator to create engaging, faceless TikTok videos that go viral. Learn the best practices for leveraging AI to automate your content creation and maximize your reach on TikTok",
    imageUrl: "https://assets-static.invideo.io/images/large/1_2a901fb916.png",
    cta: "Read Blog",
    link: "/blog/how-to-create-a-faceless-youtube-channel-with-ai-video-earn-10k-per-month"
  },
  {
    title: "Guide: From Zero to Viral",
    description: "Starting a TikTok channel? Learn how to use an AI TikTok generator to create viral, faceless videos that attract followers and boost engagement. Perfect for new and experienced creators alike.",
    imageUrl: "https://cms-article.forbesindia.com/media/images/2022/Mar/img_180993_influencermarketing.jpg",
    cta: "Read Guide",
    link: "/blog/faceless-youtube-channel-ideas"
  },
  {
    title: "Why AI TikTok Generators are Game-Changers?",
    description: "AI TikTok generators are revolutionizing content creation. Learn how to harness the power of AI to create professional faceless TikTok videos that resonate with your audience and drive engagement.",
    imageUrl: "https://entail-assets.com/mayple/62bb12300f9e11eba5ffcc8c_Contentmarketingskills1_1269da5c3ddfb009e9fc426476e1edc8_2000-1699776201636.jpg",
    cta: "Learn More"
  }
];