import { Container, Card, Title, Text, SimpleGrid } from '@mantine/core';
import { IconSparkles, IconPresentationAnalytics, IconBrandYoutube, IconBadgeCc, IconRocket, IconFileArrowRight, IconDatabase, IconBrandTabler } from '@tabler/icons-react';
import styles from './KeyFeatures.module.css';

const defaultFeatures = [
  {
    title: "AI-Powered",
    description: "Automatically generate professional videos with AI-crafted scripts, images, and voiceovers.",
    icon: <IconSparkles size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Versatile Topics",
    description: "Generate content on any subject, from science and history to anime and fiction.",
    icon: <IconPresentationAnalytics size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "High-Quality Output",
    description: "Produce videos with professional-grade quality, featuring crisp visuals and clear audio.",
    icon: <IconBrandYoutube size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Dynamic AI Subtitles",
    description: "Enhance engagement with dynamically generated AI subtitles for all your videos.",
    icon: <IconBadgeCc size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Fast Video Generation",
    description: "Create and download your AI-generated videos in just one minute for quick sharing.",
    icon: <IconRocket size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Unlimited Exports",
    description: "Enjoy unlimited exports of videos, audio, images, and text without any watermarks.",
    icon: <IconFileArrowRight size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Lifetime Storage",
    description: "Keep your content safe and accessible forever with our lifetime storage feature.",
    icon: <IconDatabase size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "User-Friendly Interface",
    description: "Easily navigate and create content with an intuitive platform designed for all skill levels.",
    icon: <IconBrandTabler size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
];

const KeyFeatures = ({features={defaultFeatures}}) => {
  return (
    <div className={styles.keyFeatures}>
      <Container fluid className={styles.container}>
        <Title className={styles.title}>Key Features</Title>

         <SimpleGrid cols={{ base: 1, sm: 2, lg: 4 }}
            spacing={{ base: 10, sm: 'lg' }}
            verticalSpacing={{ base: 'md', sm: 'xl' }}
            >
          {features.map((feature, index) => (
            <div key={index}>
              <Card shadow="sm" padding="lg" className={styles.card}>
                {/* <Card.Section>
                  <Image className={styles.cardImage} src={feature.image} height={120} alt={feature.title} />
                </Card.Section> */}
                <div className={styles.icon}>{feature.icon}</div>
                <Title order={4} className={styles.cardTitle}>{feature.title}</Title>
                <Text className={styles.cardDescription}>{feature.description}</Text>
              </Card>
            </div>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default KeyFeatures;
