// src/components/Terms.js
import { Container, Text, Title } from '@mantine/core';
import styles from './Terms.module.css';
import Footer from './Footer';
import Navbar from './Navbar';

const Terms = () => {
  return (
    <>
    <Navbar />
    <Container className={styles.termsContainer}>
      <Title order={1} className={styles.title}>Terms and Conditions</Title>
      <Text className={styles.company}>AiVideo.to</Text>

      <Title order={2} className={styles.sectionTitle}>1. Introduction</Title>
      <Text className={styles.sectionText}>
        Welcome to AIVideo.to (the "Service"), operated by AiVideo.to ("we," "our," or "us"). These Terms and Conditions ("Terms") govern your use of our Service.
      </Text>

      <Title order={2} className={styles.sectionTitle}>2. Acceptance of Terms</Title>
      <Text className={styles.sectionText}>
        By accessing or using our Service, you agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use the Service.
      </Text>

      <Title order={2} className={styles.sectionTitle}>3. Accounts</Title>
      <Text className={styles.sectionText}>
        <strong>Account Creation:</strong> To use our Service, you must create an account. You agree to provide accurate, complete, and current information during the registration process.
      </Text>
      <Text className={styles.sectionText}>
        <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account.
      </Text>

      <Title order={2} className={styles.sectionTitle}>4. Use of the Service</Title>
      <Text className={styles.sectionText}>
        <strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to use the Service in accordance with these Terms.
      </Text>
      <Text className={styles.sectionText}>
        <strong>Prohibited Uses:</strong> You agree not to use the Service for any unlawful purpose or in any way that could harm the Service, our users, or third parties.
      </Text>

      <Title order={2} className={styles.sectionTitle}>5. Fees and Payment</Title>
      <Text className={styles.sectionText}>
        <strong>Subscription Fees:</strong> Access to certain features of the Service may require you to pay subscription fees. All fees are billed in advance and are non-refundable.
      </Text>
      <Text className={styles.sectionText}>
        <strong>Payment Information:</strong> You must provide valid payment information. By providing payment information, you represent and warrant that you are authorized to use the payment method.
      </Text>

      <Title order={2} className={styles.sectionTitle}>6. Intellectual Property</Title>
      <Text className={styles.sectionText}>
        <strong>Ownership:</strong> We retain all rights, title, and interest in and to the Service, including all related intellectual property rights.
      </Text>
      <Text className={styles.sectionText}>
        <strong>User Content:</strong> You retain all rights to the content you create using the Service. By uploading content, you grant us a non-exclusive, royalty-free license to use, modify, and display your content solely for the purpose of providing the Service.
      </Text>

      <Title order={2} className={styles.sectionTitle}>7. Termination</Title>
      <Text className={styles.sectionText}>
        <strong>Termination by You:</strong> You may terminate your account at any time by contacting support@aivideo.to or filling out the cancellation form at AIVideo.to/support.
      </Text>
      <Text className={styles.sectionText}>
        <strong>Termination by Us:</strong> We may suspend or terminate your access to the Service at any time, with or without cause, and with or without notice.
      </Text>

      <Title order={2} className={styles.sectionTitle}>8. Disclaimer of Warranties</Title>
      <Text className={styles.sectionText}>
        The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted or error-free.
      </Text>

      <Title order={2} className={styles.sectionTitle}>9. Limitation of Liability</Title>
      <Text className={styles.sectionText}>
        To the fullest extent permitted by law, AiVideo.to shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Service.
      </Text>

      <Title order={2} className={styles.sectionTitle}>10. Governing Law</Title>
      <Text className={styles.sectionText}>
        These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions.
      </Text>

      <Title order={2} className={styles.sectionTitle}>11. Changes to Terms</Title>
      <Text className={styles.sectionText}>
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
      </Text>

      <Title order={2} className={styles.sectionTitle}>12. Contact Us</Title>
      <Text className={styles.sectionText}>
        If you have any questions about these Terms, please contact us at: support@aivideo.to
      </Text>

    </Container>
    <Footer />
    </>
  );
};

export default Terms;
