import { Accordion, Container, Text, Title } from '@mantine/core';
import styles from './Faqs.module.css';

const faqs = [
{
    question: 'What is a faceless YouTube channel?',
    answer: 'A faceless YouTube channel is a type of channel where the creator does not appear on camera. Instead, the content is presented using voiceovers, animations, slideshows, or other visuals.',
 },
 {
    question: 'What are the best faceless youtube channel ideas?',
    answer: 'Animation and storytelling, screen recording tutorials, software tips and tricks, gaming content, whiteboard explainer videos, meditation and relaxation music, ambient soundscapes, product reviews using stock footage.',
 },
 {
    question: 'Why create a faceless YouTube channel?',
    answer: 'Creating a faceless YouTube channel can provide privacy and anonymity, allowing creators to focus on content without the pressure of being on camera. It also offers flexibility in content creation.',
 },
 {
    question: 'How to create a faceless youtube channel?',
    answer: 'To create a faceless YouTube channel, choose a niche and produce content using screen recordings, stock footage, or animations, combined with voiceovers. Optimize your videos for SEO with clear titles and tags, and upload consistently to build your audience.',
 },
 {
    question: 'What is AI Video?',
    answer: 'AI Video is a tool that helps creators generate high-quality short videos using generative AI.',
 },
 {
    question: 'Are the videos copyright-free?',
    answer: 'We generate new videos each time with different scripts, images, and voiceovers. You can rest easy knowing your videos generated with AI Video will never get any copyright strikes.',
 },
 {
    question: 'Do I need to write prompts?',
    answer: 'No! All you need to enter is your video topic. AI Video will create the perfect video for you. No hassle of typing phrases like "trending on artstation, human-readable, unique, viral, etc." every time.',
 },
 {
    question: 'Can I generate Videos on any topic?',
    answer: 'Yes! You can generate videos on any topic you like. From Science, and History to Anime, and Fiction. Your topics can be of any style. You can go for character analysis, theory, facts, info sharing, quotes, news, opinions, and so on.',
 },
 {
    question: 'How do I generate a Video?',
    answer: 'Sign Up for an AIVideo.to account and select your plan. Then enter your topic and hit the "Create" button. Your short AI video will be ready within 1 minute.',
 },
 {
    question: 'Can I export a Video?',
    answer: 'Yes, you can download the videos you generate at any time to your device and share them with whoever and however you like.',
 },
 {
    question: 'How many Videos can I generate?',
    answer: 'You can generate the number of videos allowed by your subscription plan. The Basic plan offers 10 videos/month. The Standard plan has 50 videos/month. With the Premium plan, you can generate 100 videos/month.',
 },
 {
    question: 'What happens if I exhaust my video quota?',
    answer: 'If you love using AIVideo.to and exhaust your quota, you can wait for the next billing cycle for your quota reset or upgrade to a higher plan. Contact billing@aivideo.to for any issues or queries.',
 },
 {
    question: 'Do AI Videos have a watermark?',
    answer: 'No. Our videos don\'t come with watermarks. You own the videos you create.',
 },
 {
    question: 'Can I generate NSFW Videos?',
    answer: 'No! Our generative AI and LLM models filter for NSFW topics and words. Avoid using any NSFW or controversial phrases in your topics or video generation will fail and you will lose your credits and it may lead to your account suspension.',
 },
 {
    question: 'Can I also make long videos with AI Video?',
    answer: 'No. For now, AI Video only supports short video creation. Maybe in the future, we will launch long-form videos as well but we are not making any promise.',
 },
 {
    question: 'Who is responsible for Videos created with AI Video?',
    answer: 'The videos generated by users on AI Video are the responsibility of whoever generates them. We are not responsible for any content generated by our users on our platform.',
 },
 {
    question: 'What platforms can I post the videos on?',
    answer: 'You can post videos generated on AiVideo.to on any platform you like. You can upload to your YouTube channel or share with your Instagram/Twitter followers.',
 },
 {
    question: 'Can I make videos to educate my students?',
    answer: 'Yes, we support education initiatives and offer good discounts for educators. Send an email to support@aivideo.to with your details and we will get back to you.',
 },
];

const Faqs = () => {
  const items = faqs.map((faq, index) => (
    <Accordion.Item bg="#fff" style={{border: '1px solid #efefef'}} key={index} value={faq.question}>
      <Accordion.Control>{faq.question}</Accordion.Control>
      <Accordion.Panel py={20}>{faq.answer}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Container className={styles.faqContainer} id='#faqs'>
      <Title mb={10}>Frequently Asked Questions</Title>
      <Text size='lg'>Have doubts about AIVideo.to? Read our Faqs.</Text>
      <Accordion className={styles.accordion} variant='separated' defaultValue="What is a faceless YouTube channel?">{items}</Accordion>
    </Container>
  );
};

export default Faqs;
