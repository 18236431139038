// src/components/Signup.js
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import { Button } from "@mantine/core";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const { signUpWithEmail } = useAuth();
  const navigate = useNavigate();
  const [trackdeskInstance, setTrackdeskInstance] = useState(null);
  const [loading, setLoading] = useState(false);

   useEffect(() => {
    const loadTrackdeskScript = (callback) => {
      const existingScript = document.getElementById('trackdesk-script');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://cdn.trackdesk.com/tracking.js'; // Use HTTPS
        script.id = 'trackdesk-script';
        script.async = true;
        script.onload = () => {
          // console.log('Trackdesk script loaded');
          if (callback) callback();
        };
        script.onerror = () => {
          console.error('Failed to load Trackdesk script');
        };
        document.body.appendChild(script);
      }

      if (existingScript && callback) callback();
    };

    loadTrackdeskScript(() => {
      // Initialize Trackdesk after the script is loaded
      (function (t, d, k) {
        (t[k] = t[k] || []).push(d);
        t[d] = t[d] || t[k].f || function () {
          (t[d].q = t[d].q || []).push(arguments);
        };
      })(window, 'trackdesk', 'TrackdeskObject');

      // Set Trackdesk instance if available
      if (typeof window.trackdesk === 'function') {
        // console.log('Trackdesk is ready');
        setTrackdeskInstance(() => window.trackdesk);
      } else {
        console.error('Trackdesk is not ready');
      }
    });
  }, []);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const {user, uid, customerId} = await signUpWithEmail(email, password, name);

      // console.log(user, uid, customerId);
      
     
     // Use the captured trackdeskInstance instead of window.trackdesk
    if (typeof trackdeskInstance === 'function') {
      // console.log('tracking....');
      
      trackdeskInstance('ai-video', 'externalCid', {
        externalCid: email, // Use the customer's email here
        revenueOriginId: 'df83ea4d-dc98-4c42-83c3-5af2e393a33b'
      });
    } else {
      console.error('Trackdesk is not loaded');
    }

      navigate("/subscribe", {state: {customerId: customerId}});
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className={styles.signupContainer}>
      <form onSubmit={handleSignup} className={styles.form}>
        <h2 className={styles.heading}>Sign Up</h2>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.input}
          />
        </div>
        {/* <button type="submit" className={styles.button}>Sign Up</button> */}
        <Button type="submit" loading={loading} loaderProps={{ type: 'dots' }}>
          Sign Up
        </Button>
        <p>
          Already have an account? <Link to="/login">Log In</Link>
        </p>
      </form>
    </div>
  );
};

export default Signup;
