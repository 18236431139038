import { Container, Grid, Title, Text, Card } from '@mantine/core';
import styles from './WhyChooseAI.module.css';

const features = [
  {
    title: "Effortless Content Creation",
    description: "AI Video simplifies the video creation process, allowing you to generate high-quality content without the need for complex editing skills or writing prompts. Just enter your topic, and let AI Video do the rest, saving you time and effort."
  },
  {
    title: "Copyright-Free Videos",
    description: "Create with confidence knowing that all videos generated by AI Video are unique and copyright-free. Each video features distinct scripts, images, and voiceovers, ensuring you won't encounter copyright strikes on your content."
  },
  {
    title: "Versatile and Unlimited Topics",
    description: "With AI Video, you can explore limitless possibilities by creating videos on any topic of your choice. Whether it's science, history, anime, or fiction, AI Video supports diverse content styles like character analysis, theories, facts, quotes, and more."
  },
  {
    title: "Instant Video Generation",
    description: "Get your ideas to life in a flash with AI Video's fast and efficient video generation process. Simply sign up, enter your topic, and have your AI-generated video ready to download and share in just one minute."
  },
];

const WhyChooseAI = () => {
  return (
    <div className={styles.whyChooseAI}>
      <Container className={styles.container}>
        <Title className={styles.title}>Why Choose AI Video?</Title>
        <Grid>
          {features.map((feature, index) => (
            <Grid.Col span={12} sm={6} key={index}>
              <Card shadow="sm" padding="lg" className={styles.card}>
                <Title order={4} className={styles.cardTitle}>{feature.title}</Title>
                <Text className={styles.cardDescription}>{feature.description}</Text>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default WhyChooseAI;
