const pricingPlans = [
    {
      title: 'Starter',
      price: '$19 /mo',
      old: '$25',
      reels: '10 videos',
      priceId: "price_1PnbS0SJy4iMiCE32llICfi3",
      quota: 10,
      remaining: 10
    },
    {
      title: 'Basic',
      price: '$35 /mo',
    old: '$50',
      reels: '20 videos',
      priceId: "price_1PnbSTSJy4iMiCE3l46JRDkg",
      quota: 20,
      remaining: 20
    },
    {
      title: 'Standard',
      price: '$199 /mo',
      old: '$250',
      reels: '100 videos',
      priceId: "price_1PnbSqSJy4iMiCE3BZHTr4b2",
      quota: 100,
      remaining: 100
    },
    {
      title: 'Premium',
      price: '$349 /mo',
      old: '$400',
      reels: '200 videos',
      priceId: "price_1PnbT9SJy4iMiCE3hecKfh37",
      quota: 200,
      remaining: 200
    },
  ];


export default pricingPlans