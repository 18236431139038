// import Slider from 'react-slick';
import { Container, Stack, Text, Title } from '@mantine/core';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import styles from './VideoCarousel.module.css';

// dev
// const initialVideos = [
//   { value: "Who was Porus?", category: "History", url: "https://s3.us-east-1.amazonaws.com/remotionlambda-useast1-zc0co6mmcj/renders/wzl9ed3a60/out.mp4" },
//   { value: "What is the Doppler Effect?", category: "Science", url: "https://s3.us-east-1.amazonaws.com/remotionlambda-useast1-zc0co6mmcj/renders/64zrxppeoj/out.mp4" },
//   { value: "What if everything is a dream?", category: "Philosophy", url: "https://s3.us-east-1.amazonaws.com/remotionlambda-useast1-zc0co6mmcj/renders/mmfjf13epi/out.mp4" },
//   { value: "Who was King Baldwin IV?", category: "History", url: "https://s3.us-east-1.amazonaws.com/remotionlambda-useast1-zc0co6mmcj/renders/xu2u1z6755/out.mp4" },
//   // Add more videos as needed
// ];


const initialVideos = [
  {
    value: 'Who was Porus?',
    category: 'History',
    url: 'https://d27ug7nm5nfkza.cloudfront.net/renders/wzl9ed3a60/out.mp4',
    poster: "https://reelmacker.s3.amazonaws.com/Who-was-Porus--2024-07-30T09-14-27-894Z-VZarJkBT6CP7iHLcv4eSsprZssp2/image3.jpg"
  },
  {
    value: 'What if everything is a dream?',
    category: 'Philosophy',
    url: 'https://d27ug7nm5nfkza.cloudfront.net/renders/s4697uzmj7/out.mp4',
    poster: "https://reelmacker.s3.amazonaws.com/What-if-everything-is-a-dream--2024-08-01T15-56-31-161Z-VZarJkBT6CP7iHLcv4eSsprZssp2/image2.jpg"
  },
  { value: "Glorfindel's greatest feats", category: "Fiction", url: "https://d27ug7nm5nfkza.cloudfront.net/renders/g8y8cl4asn/out.mp4" },
    { value: "Lessons from Plato", category: "Literature", url: "https://d27ug7nm5nfkza.cloudfront.net/renders/1as78y75mu/out.mp4",  poster: "https://reelmacker.s3.amazonaws.com/Glorfindel-s-greatest-feats-2024-08-04T09-00-03-106Z-VZarJkBT6CP7iHLcv4eSsprZssp2/image3.jpg" },
  {
    value: 'Who was Julius Caesar?',
    category: 'History',
    url: 'https://d27ug7nm5nfkza.cloudfront.net/renders/2ci1vgc2f0/out.mp4',
    poster: "https://reelmacker.s3.amazonaws.com/Who-was-Julius-Caesar--2024-07-29T10-56-04-052Z-VZarJkBT6CP7iHLcv4eSsprZssp2/image5.jpg"
  },
  // Add more videos as needed
];

const VideoCarousel = ({title="Made with AI Video", description="Turn any topic in to short Videos within 1 minute"}) => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 400, // Speed of the slide transition in ms
  //   slidesToShow: 6,
  //   // slidesToScroll: 1,
  //   autoplay: false,
  //   autoplaySpeed: 0, // Set to 0 for continuous scroll
  //   cssEase: 'linear',
  //   pauseOnHover: false, // Ensure carousel pauses on hover
  //   pauseOnFocus: false,
  //   swipeToSlide: true,
  //   arrows: false,
  //   // prevArrow: <IconArrowLeft />,
  //   // nextArrow: <IconArrowRight />,
  //   centerMode: false,
  //   centerPadding: '40px', // Adjust to create space between slides
  //   // lazyLoad: 'ondemand',
  //   responsive: [
  //     {
  //       breakpoint: 768, // Mobile breakpoint
  //       settings: {
  //         slidesToShow: 1, // Show 1 slide on mobile
  //         centerMode: false, // Disable center mode for single slide
  //       },
  //     },
  //     {
  //       breakpoint: 1024, // Tablet and small desktop
  //       settings: {
  //         slidesToShow: 2, // Show 2 slides on smaller desktops
  //         centerMode: true, // Center mode enabled for better view
  //         centerPadding: '20px', // Adjust padding as needed
  //       },
  //     },
  //   ],
  // };

  return (
    <Container className={styles.carouselContainer} fluid>
      <Stack mb={40}>
          <Title size="h2" className={styles.title}>{title}</Title>
          <Text size="lg" className={styles.title}>{description}</Text>
      </Stack>
      <div className={styles.row}>
      {/* <div className={styles.gradientOverlay}></div> */}
      {/* <Slider {...settings}> */}
        {initialVideos.map((video) => (
          <div key={video.value} className={styles.videoContainer}>
            <video loading="lazy" width={306} height={544} preload="metadata" className={styles.video} src={video.url} muted loop autoPlay poster={video.poster} />
          </div>
        ))}
      </div>
      {/* </Slider> */}
      {/* <div className={styles.gradientOverlayRight}></div> */}
    </Container>
  );
};

export default VideoCarousel;
