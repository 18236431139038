import { useState } from 'react';
import { Card, Button, Text, Title, Badge, Alert } from '@mantine/core';
import styles from './Billing.module.css';
import Sidebar from './Sidebar';
import { IconNote } from '@tabler/icons-react';

const PLANS = {
  10: "Basic",
  50: "Standard",
  100: "Premium"
}

const Billing = ({ userData }) => {
  const [showUpgradeInfo, setShowUpgradeInfo] = useState(false);
  const [showCancelInfo, setShowCancelInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const manageSubscription = async () => {
    setLoading(true)
    const returnUrl = window.location.href

    try {
      // Make the GET request to the API endpoint
      const response = await fetch(
        `https://spiff.guru/create-portal-session?customer_id=${userData?.customerId}&return_url=${returnUrl}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        // Open the Customer Portal session URL in a new tab
        window.open(data.url, '_blank');
      } else {
        // Handle errors from the server
        const errorData = await response.json();
        console.error('Error managing subscription:', errorData);
        alert('Failed to manage subscription: ' + errorData.error);
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Failed to manage subscription. Please try again later.');
    } finally {
      setLoading(false)
    }
    
  };

  const handleUpgradeClick = () => {
    setShowCancelInfo(false)
    setShowUpgradeInfo(true)
  };

  const handleCancelClick = () => {
    setShowUpgradeInfo(false)
    setShowCancelInfo(true)
  };

  return (
    <div className={styles.section}>
      <Sidebar />
      <div className={styles.billing}>
        <Card shadow="sm" padding="lg" className={styles.card}>
          <Title order={3} className={styles.title}>Billing Information</Title>
          <Text className={styles.text}><strong>Account Email:</strong> {userData?.email}</Text>
          {/* <Text className={styles.text}>Free Trial: {userData.email}</Text> */}

          <Text className={styles.text}><strong>Current Plan:</strong> {userData?.plan}</Text>
          {userData?.freeTrial ? <Text className={styles.text}><strong>Free Trial:</strong> <Badge color='green'>{userData?.freeTrial}</Badge></Text> : ""}
          <Text className={styles.text}><strong>Usage Remaining:</strong> {userData?.remaining} / {userData?.quota}</Text>

          {
            userData?.freeTrial ?  
              <Alert variant="light" color="blue" title="Free Trial Notice" icon={<IconNote />}>
                Your usage quota will reflect your selected pricing tier after your 7 day free trial ends. If you don't see your updated quota after the end of Free trial please reach out to support@aivideo.to
              </Alert>
            : ""
          }

          <Button variant="" color="blue" onClick={manageSubscription} className={styles.button} disabled={loading} loading={loading}>
            Manage Subscription
          </Button>
          {/* <Button variant="outline" color="red" onClick={handleCancelClick} className={styles.button}>
            Cancel Subscription
          </Button> */}
        </Card>

        {showUpgradeInfo && (
          <Card shadow="sm" padding="lg" className={styles.card}>
            <Title order={3} className={styles.title}>Upgrade Plan</Title>
            <Text className={styles.text}>
              To upgrade your plan, send an email to <a href="mailto:billing@aivideo.to?subject=Upgrade Plan">billing@aivideo.to</a> with the subject "Upgrade Plan". In the body of the email, include the following information:
            </Text>
            <Text className={styles.text}>
              Account Email: {userData.email}<br />
              Current Plan: {PLANS[userData.quota]}<br />
              Upgrade Plan: [Specify desired plan]
            </Text>
            <Text size="sm" color="dimmed" className={styles.text}>Upgrade requests will be processed within 12 hours.</Text>
          </Card>
        )}

        {showCancelInfo && (
          <Card shadow="sm" padding="lg" className={styles.card}>
            <Title order={3} className={styles.title}>Cancel Subscription</Title>
            <Text className={styles.text}>
              To cancel your subscription, send an email to <a href="mailto:billing@aivideo.to?subject=Cancel Subscription">billing@aivideo.to</a> with the subject "Cancel Subscription". In the body of the email, include the following information:
            </Text>
            <Text className={styles.text}>
              Account Email: {userData.email}<br />
              Current Plan: {PLANS[userData.quota]}<br />
              Reason for Cancellation: [Specify reason]
            </Text>
            <Text size="sm" color="dimmed" className={styles.text}>Cancellation requests will be processed within 12 hours.</Text>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Billing;
