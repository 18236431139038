import { Container, SimpleGrid, Text, Title, Anchor, Stack } from '@mantine/core';
import styles from './Footer.module.css';
import { IconBrandTwitter } from '@tabler/icons-react';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <Container  fluid className={styles.container}>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="lg" breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          <Stack className={styles.column}>
            <Title order={5} className={styles.title}>Product</Title>
            <Anchor href="/" className={styles.link}>AI Short Videos Generator</Anchor>
            <Anchor href="/pricing" className={styles.link}>Pricing</Anchor>
            <Anchor href="/ai-music-video-generator" className={styles.link}>AI Music Video Generator</Anchor>
            <Anchor href="/ai-tiktok-video-generator" className={styles.link}>AI TikTok Generator</Anchor>
            <Anchor href="https://ai-video.trackdesk.com/sign-up" target='_blank' rel='nofollow' className={styles.link}>Become an Affiliate (40% Commission)</Anchor>
          </Stack>
          <Stack className={styles.column}>
            <Title order={5} className={styles.title}>About</Title>
            <Anchor href="/privacy" className={styles.link}>Privacy Policy</Anchor>
            <Anchor href="/terms" className={styles.link}>Terms of Service</Anchor>
          </Stack>
          <Stack className={styles.column}>
            <Title order={5} className={styles.title}>Contact</Title>
            <Anchor href="mailto:support@aivideo.to" className={styles.link}>support@aivideo.to</Anchor>
            <Anchor href="https://x.com/AiVideoTo" target='_blank' className={styles.link}><IconBrandTwitter /></Anchor>
          </Stack>
        </SimpleGrid>
        <Text align="center" className={styles.copyright}>
          © 2024 AI Video. All rights reserved.
        </Text>
      </Container>
    </div>
  );
};

export default Footer;
