import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Success.module.css'; // CSS module
import loadinggif from '../assets/loading.gif'
import pricingPlans from '../plans';

const stripeApiKey = import.meta.env.VITE_APP_STRIPE_SECRET_KEY; // Replace with your actual Stripe secret key

// sk_test_51Nxpr5SJy4iMiCE30JbBTjsihKkiK2NsgqkMZDL5nLAMx1e2bQccyI69sxGmcwTjYgFN560hxyy2OfQS2mGDPBGw00g2OwVcC3

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get('customerId');
    const userId = searchParams.get('userId');
    const email = searchParams.get('email');

    const myPlan = pricingPlans?.find(plan => plan.priceId===userId)

    // console.log(myPlan);

    const checkSubscriptionAndUpdateSheet = async () => {
      try {
        // Check if the customer has an active subscription with Stripe API
        const stripeResponse = await fetch(`https://api.stripe.com/v1/customers/${customerId}/subscriptions`, {
          headers: {
            'Authorization': `Bearer ${stripeApiKey}`
          }
        });
        const stripeData = await stripeResponse.json();        
        
        const hasTrialActiveSubscription = stripeData.data.some(subscription => subscription.status === 'trialing');
        const hasActiveSubscription = stripeData.data.some(subscription => subscription.status === 'active');

        // console.log('hasActiveSubscription', hasActiveSubscription);
        if (hasActiveSubscription || hasTrialActiveSubscription) {
          // Fetch data from the Google Sheets API
          const sheetResponse = await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users?filter[customerId]=${customerId}`);
          const sheetData = await sheetResponse.json();
          const row = sheetData?.users[0]

          // console.log(row);

          if (row && row.subscriptionStatus !== 'active') {
            // Update the row in the Google Sheets API
            await fetch(`https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users/${row.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                user: {
                  subscriptionStatus: hasActiveSubscription ? 'active' : "",
                  subscriptionDate: new Date().toISOString(),
                  quota: myPlan?.quota,
                  remaining: myPlan?.remaining,
                  plan: myPlan.title,
                  freeTrial: hasTrialActiveSubscription ? "active" : ""
                }
              })
            });
          }
        }

        navigate('/dashboard')
      } catch (error) {
        console.error('Error:', error);
      }
    };

    checkSubscriptionAndUpdateSheet();
  }, [location]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.step}>
          <img src={loadinggif} alt='laoding' />
          <h2>
            Processing your request. Please Wait. Do not leave this tab or close the browser...
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Success;