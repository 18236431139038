// src/components/UseCases.js
import { Container, SimpleGrid, Text, Button, Title, Image } from '@mantine/core';
import styles from './UseCases.module.css';
// import contentMarketing from '../assets/content-marketing.webp'

// const useCases = [
//   {
//     title: "Faceless YouTube Channel",
//     description: "A creator can maintain a successful faceless YouTube channel, generating substantial monthly revenue, without appearing on camera. Explore best faceless YouTube channel ideas and learn how to create a faceless youtube channel with AI Video in no time.",
//     imageUrl: "https://assets-static.invideo.io/images/large/1_2a901fb916.png",
//     cta: "Read Blog",
//     link: "/blog/how-to-create-a-faceless-youtube-channel-with-ai-video-earn-10k-per-month"
//   },
//   {
//     title: "Influencer Engagement",
//     description: "Influencers can keep their followers engaged with short, informative videos. AI Video allows them to produce consistent and high-quality content without spending hours filming and editing.",
//     imageUrl: "https://cms-article.forbesindia.com/media/images/2022/Mar/img_180993_influencermarketing.jpg",
//     cta: "Read Case Study"
//   },
//   {
//     title: "Content Marketing and Social Media",
//     description: "Marketers can drive traffic to their business websites through AI-generated videos. These videos can enhance content marketing strategies and boost social media presence, attracting more visitors and potential customers.",
//     imageUrl: "https://entail-assets.com/mayple/62bb12300f9e11eba5ffcc8c_Contentmarketingskills1_1269da5c3ddfb009e9fc426476e1edc8_2000-1699776201636.jpg",
//     cta: "Learn More"
//   }
// ];


const defaultUseCases = [
  {
    title: "Faceless YouTube Channel",
    description: "A creator can maintain a successful faceless YouTube channel, generating substantial monthly revenue, without appearing on camera. Explore best faceless YouTube channel ideas and learn how to create a faceless youtube channel with AI Video in no time.",
    imageUrl: "https://assets-static.invideo.io/images/large/1_2a901fb916.png",
    cta: "Read Blog",
    link: "/blog/how-to-create-a-faceless-youtube-channel-with-ai-video-earn-10k-per-month"
  },
  {
    title: "Faceless YouTube Channel Ideas",
    description: "Discover unique faceless YouTube channel ideas that allow you to create engaging content without showing your face. From voiceover tutorials to animated storytelling, explore creative ways to build a successful channel while maintaining your privacy.",
    imageUrl: "https://cms-article.forbesindia.com/media/images/2022/Mar/img_180993_influencermarketing.jpg",
    cta: "Explore Collection",
    link: "/blog/faceless-youtube-channel-ideas"
  },
  {
    title: "Content Marketing and Social Media",
    description: "Marketers can drive traffic to their business websites through AI-generated videos. These videos can enhance content marketing strategies and boost social media presence, attracting more visitors and potential customers.",
    imageUrl: "https://entail-assets.com/mayple/62bb12300f9e11eba5ffcc8c_Contentmarketingskills1_1269da5c3ddfb009e9fc426476e1edc8_2000-1699776201636.jpg",
    cta: "Learn More"
  }
];

const UseCases = ({useCases={defaultUseCases}}) => {
  return (
    <Container fluid className={styles.useCasesContainer}>
      {useCases?.map((useCase, index) => (
        <SimpleGrid
          cols={{ base: 1, md: 2}}
          spacing={{ base: 10, sm: 'xl' }}
          breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
          key={index}
          className={styles.useCaseRow}
        >
          {index % 2 === 0 ? (
            <>
              <div className={styles.imageCol}>
                <Image  loading="lazy"src={useCase.imageUrl} alt={useCase.title} className={styles.image} />
              </div>
              <div className={styles.textCol}>
                <Title order={3} className={styles.title}>{useCase.title}</Title>
                <Text className={styles.description}>{useCase.description}</Text>
                <Button className={styles.cta} component={"a"} href={useCase.link ? useCase.link : "#" } >{useCase.cta}</Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.textCol}>
                <Title order={3} className={styles.title}>{useCase.title}</Title>
                <Text className={styles.description}>{useCase.description}</Text>
                <Button className={styles.cta} component={"a"} href={useCase.link ? useCase.link : "#" } >{useCase.cta}</Button>
              </div>
              <div className={styles.imageCol}>
                <Image loading="lazy" src={useCase.imageUrl} alt={useCase.title} className={styles.image} />
              </div>
            </>
          )}
        </SimpleGrid>
      ))}
    </Container>
  );
};

export default UseCases;
