import { Badge, Card, Center, Code, Mark, Text, Title } from '@mantine/core';
import styles from './UpgradeCard.module.css';
import { IconInfoCircleFilled } from '@tabler/icons-react';

const PLANS = {
  10: "Basic",
  50: "Standard",
  100: "Premium"
}

const UpgradeCard = ({ remaining, accountEmail, quota }) => {
  if (remaining > 0) {
    return null;
  }

  return (
    <Card shadow="sm" padding="lg" className={styles.card}>
      <Center>
        <IconInfoCircleFilled size="5rem" stroke={2.5} color="white" />
      </Center>
      <Title order={3} mb={15} className={styles.title}><Mark>Quota Exhausted</Mark></Title>
      <Text className={styles.text} mb={15}>Your quota has been exhausted. You can wait for the next billing cycle for quota reset or upgrade your plan.
      </Text> 
      <Text className={styles.text} mb={15}><Mark>To upgrade your plan,</Mark> send an email to <a className={styles.link} href="mailto:billing@aivideo.to?subject=Upgrade Plan" target='_blank'>billing@aivideo.to</a> with the subject "Upgrade Plan". In the body of the email, include the following information:</Text>
      <code className={styles.code}>
        Account Email: {accountEmail}<br />
        Current Plan: {PLANS[quota]}<br />
        Upgrade Plan: <Mark>[Specify desired plan]</Mark>
      </code>
      
      <Center>
        <Badge size='lg'>Upgrade requests will be processed within 12 hours.</Badge>
      </Center>
    </Card>
  );
};

export default UpgradeCard;
