import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Title, Text, Loader, Button, Skeleton, Group, Card, Accordion } from '@mantine/core';
import { IconDownload, IconHeadphones, IconMusic, IconPhoto, IconPlus } from '@tabler/icons-react';
import styles from './MusicDetail.module.css';
import Sidebar from './Sidebar';

const MusicDetail = () => {
  const { id } = useParams();
  const [musicVideo, setMusicVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/musicVideos/${id}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setMusicVideo(json.musicVideo);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching music video:', error);
        setLoading(false);
      });
  }, [id]);

  const handleDownload = (url, version) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${musicVideo.title}_v${version}.mp4`);
    link.setAttribute('target', '_blank')
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div style={{textAlign: 'center', marginTop: '10vh'}}>
        <Loader color="indigo" size="xl" />;
        <Container>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </div>
    )
  }

  if (!musicVideo) {
    return <Text className={styles.error}>Music video not found</Text>;
  }

  return (
    <div className={styles.section}>
      <Sidebar />
      <div className={styles.musicDetail}>
        <Container className={styles.container}>
          <Group className={styles.header}>
            <h2 style={{color: "#474f85"}}><IconMusic size="1.2rem" stroke={2.5} /> {musicVideo.title}</h2>
            <Button className={styles.button} component={Link} to="/ai-music-video-generator" leftSection={<IconPlus size="1rem" stroke={2.5} />}>New Music Video</Button>
          </Group>
          
          <div className={styles.layout}>
            <div className={styles.left}>
              {/* Version 1 */}
              <div className={styles.videoContainer}>               
                <video controls className={styles.video}>
                  <source src={musicVideo.videoUrl1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <Button onClick={() => handleDownload(musicVideo.videoUrl1, 1)} className={styles.button} leftSection={<IconDownload />} style={{width: 300}}>
                Download Version 1
              </Button>

              {/* Version 2 */}
              <div className={styles.videoContainer}>               
                <video controls className={styles.video}>
                  <source src={musicVideo.videoUrl2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <Button onClick={() => handleDownload(musicVideo.videoUrl2, 2)} className={styles.button} leftSection={<IconDownload />} style={{width: 300}}>
                Download Version 2
              </Button>
            </div>

            <div className={styles.right}>
              <Accordion defaultValue="Music" variant="separated">
                <Accordion.Item value={'Music'}>
                  <Accordion.Control icon={<IconHeadphones />}>{'Music Tracks'}</Accordion.Control>
                  <Accordion.Panel>
                    <div className={styles.audioContainer}>
                      <Text size="sm" weight={500}>Version 1</Text>
                      <audio controls src={musicVideo.musicUrl1}>
                        Your browser does not support the audio element.
                      </audio>
                      <Text size="sm" weight={500} mt="md">Version 2</Text>
                      <audio controls src={musicVideo.musicUrl2}>
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                
                <Accordion.Item value={'Images'}>
                  <Accordion.Control icon={<IconPhoto />}>{'Generated Images'}</Accordion.Control>
                  <Accordion.Panel>
                    <div style={{display: 'flex', overflow: 'scroll'}}>
                      {musicVideo?.images?.split(",").map((image, index) => (
                        <img 
                          className={styles.image} 
                          style={{height: 'auto', width: '40%'}} 
                          key={index} 
                          src={image} 
                          alt={`Generated image ${index + 1}`} 
                        />
                      ))}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={'Tags'}>
                  <Accordion.Control>{'Tags'}</Accordion.Control>
                  <Accordion.Panel>
                    <div className={styles.tags}>
                      {musicVideo.tags.split(" ").map((tag, index) => (
                        <Text key={index} className={styles.tag}>
                          {tag.trim()}
                        </Text>
                      ))}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={'Lyrics'}>
                  <Accordion.Control>{'Lyrics'}</Accordion.Control>
                  <Accordion.Panel>
                    <Text className={styles.lyrics}>
                      {musicVideo.lyrics.replaceAll("\\n", "\n")}
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>                
            </div>                
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MusicDetail;