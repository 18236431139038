import { useEffect, useState } from 'react';
import { Container, SimpleGrid, Card, Image, Text, Loader, Skeleton, Button, Group, Divider, ScrollArea } from '@mantine/core';
import { Link } from 'react-router-dom';
import styles from './FetchReels.module.css';
import Sidebar from './Sidebar';
import { IconLayoutDashboard, IconPlus, IconSparkles, IconVideo, IconMusic } from '@tabler/icons-react';
import { useAuth } from '../contexts/AuthContext';

const FetchContent = () => {
  const { currentUser } = useAuth();
  
  const [reels, setReels] = useState([]);
  const [musicVideos, setMusicVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const reelsUrl = `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/reels?filter[uid]=${currentUser?.uid}`;
        const musicUrl = `https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/musicVideos?filter[uid]=${currentUser?.uid}`;
        
        const [reelsResponse, musicResponse] = await Promise.all([
          fetch(reelsUrl),
          fetch(musicUrl)
        ]);
        
        const [reelsData, musicData] = await Promise.all([
          reelsResponse.json(),
          musicResponse.json()
        ]);
        
        setReels(reelsData.reels);
        setMusicVideos(musicData.musicVideos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching content:', error);
        setLoading(false);
      }
    };

    fetchContent();
  }, [currentUser?.uid]);

  if (loading) {
    return (
      <div style={{textAlign: 'center', marginTop: '10vh'}}>
        <Loader color="indigo" size="xl" />
        <Container>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </div>
    );
  }

  const NoContent = ({ type, createPath }) => (
    <div className={styles.noContent}>
      <Card shadow="sm" padding="xl" className={styles.card}>
        <Text weight={700} size="lg" mt="md">
          No {type} Found
        </Text>
        <Text size="lg" color="dimmed" mt="xs" mb="xs">
          It looks like you haven't created any {type.toLowerCase()} yet. Start by creating your first {type.toLowerCase()}!
        </Text>
        <Button size='lg' component={Link} to={createPath} className={styles.button} leftSection={<IconSparkles />}>
          Create Your First {type}
        </Button>
      </Card>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.contentArea}>
        <Container className={styles.container}>
          <div className={styles.header}>
            <h2><IconLayoutDashboard size="1.1rem" stroke={2.5} /> Dashboard</h2>
            <Group>
              <Button className={styles.button} component={Link} to="/ai-music-video-generator" leftSection={<IconMusic size="1rem" stroke={2.5} />}>
                New Music Video
              </Button>
              <Button className={styles.button} component={Link} to="/generate-new-video" leftSection={<IconPlus size="1rem" stroke={2.5} />}>
                New Video
              </Button>
            </Group>
          </div>

          <div className={styles.sectionsContainer}>
            {/* Info Videos Section */}
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}><IconVideo size="1.5rem" stroke={2.5} /> My Videos</h3>
              <ScrollArea h={300} className={styles.scrollArea}>
                <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="md">
                  {reels.length ? reels.map((reel, index) => (
                    <Card key={index} shadow="sm" padding="lg" className={styles.card} component={Link} to={`/ai-video/${reel.id}`}>
                      <Text weight={500} size="lg">
                        {reel.title}
                      </Text>
                    </Card>
                  )) : (
                    <NoContent type="Videos" createPath="/generate-new-video" />
                  )}
                </SimpleGrid>
              </ScrollArea>
            </div>

            {/* Music Videos Section */}
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}><IconMusic size="1.5rem" stroke={2.5} /> My Music Videos</h3>
              <ScrollArea h={300} className={styles.scrollArea}>
                <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="md">
                  {musicVideos.length ? musicVideos.map((video, index) => (
                    <Card key={index} shadow="sm" padding="lg" className={styles.card} component={Link} to={`/music-video/${video.id}`}>
                      <Text weight={500} size="lg">
                        {video.title}
                      </Text>
                      <Text size="sm" color="dimmed" mt="xs">
                        {video.tags}
                      </Text>
                    </Card>
                  )) : (
                    <NoContent type="Music Videos" createPath="/ai-music-video-generator" />
                  )}
                </SimpleGrid>
              </ScrollArea>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FetchContent;