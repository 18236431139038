import { Container, SimpleGrid, Card, Title, Text, List, ThemeIcon, Badge, Center, Button } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import styles from './Pricing.module.css';
import Navbar from './Navbar';
import Footer from './Footer';
import HeroSection from './HeroSection';
import { Link } from 'react-router-dom';

const pricingPlans = [
  {
    title: 'Starter',
    price: '$19 /mo',
    old: '$25',
    reels: '10 videos',
    priceId: "price_1PnbS0SJy4iMiCE32llICfi3",
    quota: 10,
    remaining: 10
  },
  {
    title: 'Basic',
    price: '$35 /mo',
    old: '$50',
    reels: '20 videos',
    priceId: "price_1PnbSTSJy4iMiCE3l46JRDkg",
    quota: 20,
    remaining: 20
  },
  {
    title: 'Standard',
    price: '$199 /mo',
    old: '$250',
    reels: '100 videos',
    priceId: "price_1PnbSqSJy4iMiCE3BZHTr4b2",
    quota: 100,
    remaining: 100
  },
  {
    title: 'Premium',
    price: '$349 /mo',
    old: '$400',
    reels: '200 videos',
    priceId: "price_1PnbT9SJy4iMiCE3hecKfh37",
    quota: 200,
    remaining: 200
  },
];

const tags = [
  "NEW 🎉",
  "POPULAR 🔥",
  "RECOMMENDED ✅",
  "SUPERUSER 😎"
]

const colors = [
  "grape",
  "pink",
  "violet",
  "red"
]

const features = [
  "Music Videos",
  "Info Videos",
  "No Watermarks",
  "Unlimited Exports (videos, audio, images, text)",
  "Dynamic AI Subtitles"
];

const Pricing = () => {
  return (
    <div className={styles.pricing} id='#pricing'>
      <Navbar />

      <PricingCards />
      
      <HeroSection title="Run a faceless youtube channel with AI Video." description="Pursue your faceless youtube channel ideas wiht minimal effort and time. Create shorts with AI Video and grow your YouTube channel." bg="#fff" showDemo={false} secondaryCTA={{text: "Generate AI Music Videos", link: "/ai-music-video-generator", show: true, icon: "music"}} />
      <Footer />
    </div>
  );
};

export const PricingCards = () => {
  return (
      <Container className={styles.container}>
        <Title className={styles.title}>Pricing Plans</Title>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4 }}
            spacing={{ base: 10, sm: 'lg' }} verticalSpacing={{ base: 'md', sm: 'xl' }} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          {pricingPlans.map((plan, index) => (
            <Card key={index} shadow="sm" padding="xl" className={styles.card}>
              <Title order={2} className={styles.planTitle}>{plan.title}</Title>
              <Center>
               {tags[index] && <Badge size='lg' variant="outline" color={colors[index]}>{tags[index]}</Badge>}
              </Center>
              <div className={styles.priceContainer}>
                <Text className={styles.oldPrice}>{plan?.old}</Text>
                <Text className={styles.price} variant="gradient" gradient={{ from: '#474f85', to: '#51e3d4', deg: 90 }}>
                  {plan.price}
                </Text>
              </div>
              {/* <Text className={styles.price} variant="gradient"
      gradient={{ from: '#474f85', to: '#51e3d4', deg: 90 }}>{plan.price}</Text> */}
              <Text className={styles.reels}>{plan.reels} /mo</Text>
              <List
                spacing="xs"
                size="sm"
                icon={
                  <ThemeIcon color="teal" variant='transparent' size={24} radius="xl">
                    <IconCheck size={18} />
                  </ThemeIcon>
                }

                className={styles.list}
              >
                {features.map((feature, idx) => (
                  <List.Item key={idx}>{feature}</List.Item>
                ))}
              </List>

              <Button className={styles.button} component={Link} to='/signup'>Start Creating</Button>
              {/* <Button color="blue" fullWidth className={styles.button}>
                Choose Plan
              </Button> */}
            </Card>
          ))}
        </SimpleGrid>
      </Container>
  )
}

export default Pricing;
