import styles from "./App.module.css";
import { Container } from '@mantine/core';
import { Helmet } from "react-helmet";
import { PricingCards } from "./components/Pricing";
import { IconBadgeCc, IconBrandTabler, IconBrandYoutube, IconDatabase, IconFileArrowRight, IconPresentationAnalytics, IconRocket, IconSparkles } from "@tabler/icons-react"

import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import VideoCarousel from './components/VideoCarousel';
import KeyFeatures from './components/KeyFeatures';
import UseCases from './components/UseCases';
import WhyChooseAI from './components/WhyChooseAI';
import Faqs from './components/Faqs';
import Footer from './components/Footer';


// Lazy load components
// const Navbar = React.lazy(() => import('./components/Navbar'));
// const HeroSection = React.lazy(() => import('./components/HeroSection'));
// const VideoCarousel = React.lazy(() => import('./components/VideoCarousel'));
// const KeyFeatures = React.lazy(() => import('./components/KeyFeatures'));
// const UseCases = React.lazy(() => import('./components/UseCases'));
// const WhyChooseAI = React.lazy(() => import('./components/WhyChooseAI'));
// const Faqs = React.lazy(() => import('./components/Faqs'));
// const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://aivideo.to/",
          "name": "AI Video",
          "description": "The ultimate AI video generator that creates videos from text prompts within 1 minute.",
          "image": "https://aivideo.to/hero.png",
          "publisher": {
            "@type": "Organization",
            "name": "AI Video",
            "logo": {
              "@type": "ImageObject",
              "url": "https://aivideo.to/logo.png"
            }
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://aivideo.to/"
          }
        }
        )}
      </script>
    </Helmet>
      <Container fluid className={styles.container}>
        <Navbar />
          <HeroSection title="Create Faceless YouTube Channel with AI Video" description="Have any faceless youtube channel ideas? Effortlessly create stunning youtube shorts with AI Video and monetize your faceless youtube channel." showDemo={false} secondaryCTA={{text: "Generate AI Music Videos", link: "/ai-music-video-generator", show: true, icon: "music"}} />
          <VideoCarousel />
          <KeyFeatures features={features} />
          <UseCases useCases={useCases} />
          <WhyChooseAI />

        <PricingCards />

          <Faqs />
          <HeroSection title="Run a faceless youtube channel with AI Video." description="Pursue your faceless youtube channel ideas wiht minimal effort and time. Create shorts with AI Video and grow your YouTube channel." bg="#fff" showDemo={false} secondaryCTA={{text: "Generate AI Music Videos", link: "/ai-music-video-generator", show: true, icon: "music"}} />
          <Footer />
      </Container>
    </>
  );
}

export default App;

const useCases = [
  {
    title: "Faceless YouTube Channel",
    description: "A creator can maintain a successful faceless YouTube channel, generating substantial monthly revenue, without appearing on camera. Explore best faceless YouTube channel ideas and learn how to create a faceless youtube channel with AI Video in no time.",
    imageUrl: "https://assets-static.invideo.io/images/large/1_2a901fb916.png",
    cta: "Read Blog",
    link: "/blog/how-to-create-a-faceless-youtube-channel-with-ai-video-earn-10k-per-month"
  },
  {
    title: "Faceless YouTube Channel Ideas",
    description: "Discover unique faceless YouTube channel ideas that allow you to create engaging content without showing your face. From voiceover tutorials to animated storytelling, explore creative ways to build a successful channel while maintaining your privacy.",
    imageUrl: "https://cms-article.forbesindia.com/media/images/2022/Mar/img_180993_influencermarketing.jpg",
    cta: "Explore Collection",
    link: "/blog/faceless-youtube-channel-ideas"
  },
  {
    title: "Content Marketing and Social Media",
    description: "Marketers can drive traffic to their business websites through AI-generated videos. These videos can enhance content marketing strategies and boost social media presence, attracting more visitors and potential customers.",
    imageUrl: "https://entail-assets.com/mayple/62bb12300f9e11eba5ffcc8c_Contentmarketingskills1_1269da5c3ddfb009e9fc426476e1edc8_2000-1699776201636.jpg",
    cta: "Learn More"
  }
];

const features = [
  {
    title: "AI-Powered",
    description: "Automatically generate professional videos with AI-crafted scripts, images, and voiceovers.",
    icon: <IconSparkles size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Versatile Topics",
    description: "Generate content on any subject, from science and history to anime and fiction.",
    icon: <IconPresentationAnalytics size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "High-Quality Output",
    description: "Produce videos with professional-grade quality, featuring crisp visuals and clear audio.",
    icon: <IconBrandYoutube size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Dynamic AI Subtitles",
    description: "Enhance engagement with dynamically generated AI subtitles for all your videos.",
    icon: <IconBadgeCc size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Fast Video Generation",
    description: "Create and download your AI-generated videos in just one minute for quick sharing.",
    icon: <IconRocket size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Unlimited Exports",
    description: "Enjoy unlimited exports of videos, audio, images, and text without any watermarks.",
    icon: <IconFileArrowRight size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "Lifetime Storage",
    description: "Keep your content safe and accessible forever with our lifetime storage feature.",
    icon: <IconDatabase size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
  {
    title: "User-Friendly Interface",
    description: "Easily navigate and create content with an intuitive platform designed for all skill levels.",
    icon: <IconBrandTabler size={48} color="#474f85" />,
    image: "https://via.placeholder.com/150"
  },
];