import { Container, Group, Button, Text, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {

  const {currentUser} = useAuth()
  
  return (
    <div className={styles.navbar}>
      <Container className={styles.container}>
        <Anchor
          variant="gradient"
          gradient={{ from: 'pink', to: 'yellow' }}
          fw={500}
          fz="lg"
          href="/"
        >
          <Text className={styles.brand}>AI Video</Text>
        </Anchor>

        <Group>
          <Anchor
            variant="gradient"
            gradient={{ from: '#51e3d4', to: '#f3f9fb' }}
            fw={500}
            fz="lg"
            href="https://ai-video.trackdesk.com/sign-up"
          >
            Affiliates (40%)
          </Anchor>
          <Anchor
            variant="gradient"
            gradient={{ from: '#51e3d4', to: '#f3f9fb' }}
            fw={500}
            fz="lg"
            href="/pricing"
          >
            Pricing
          </Anchor>
          {
            currentUser?.displayName ?
            <Button className={styles.button} component={Link} to="/dashboard">Dashboard</Button>
            :
            <Group position="right" className={styles.buttons}>
              <Button variant="outline" className={styles.button} component={Link} to="/login">Login</Button>
              <Button className={styles.button} component={Link} to="/signup">Signup</Button>
            </Group>
          }
        </Group>
      </Container>
    </div>
  );
};

export default Navbar;
